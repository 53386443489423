@charset "UTF-8";

/* ============= Color ============ */
$var__base--color                         : #E8ECED;
$var__base--font-size                     : 14px;
$var__base--font-weight                   : 500;
$var__base--font-family                   : 'Barlow', sans-serif;
$var__base--letter-spacing                : 0.02em;

$var__primary--color                      : #E8ECED;
$var__secondary--color                    : #2B3847;
$var__tertiary--color                     : #C9CFD4;

$var__light-grey--color-1                 : #F7F8F9;
$var__light-grey--color-2                 : #EFF1F3;
$var__light-grey--color-3                 : #E8ECED;
$var__light-grey--color-4                 : #E0E3E6;
$var__grey--color-1                       : #C9CFD4;
$var__grey--color-2                       : #818F99;
$var__grey--color-3                       : #3B4957;
$var__grey--color-4                       : #2B3847;
$var__dark-grey--color-1                  : #1A2430;
$var__dark-grey--color-2                  : #141D27;
$var__dark-grey--color-3                  : #111921;
$var__dark-grey--color-4                  : #0E1117;

$var__primary-background--color           : #F7F8F9;
$var__secondary-background--color         : #1A2430;
$var__tertiary-background--color          : #0E1117;

/* ============= Heading ============ */

$var__heading--color                      : #E8ECED;
$var__heading--font-weight                : 600;

$var__h1--color                           : $var__heading--color;
$var__h1--font-size                       : 28px;
$var__h1--font-weight                     : $var__heading--font-weight;

$var__h2--color                           : $var__heading--color;
$var__h2--font-size                       : 20px;
$var__h2--font-weight                     : $var__heading--font-weight;

$var__h3--color                           : $var__heading--color;
$var__h3--font-size                       : 14px;
$var__h3--font-weight                     : 700;

$var__h4--color                           : $var__heading--color;
$var__h4--font-size                       : 12px;
$var__h4--font-weight                     : 700;

/* ============= Link ============ */

$var__base-link--color                    : #818F99;
$var__base-link--color--hover             : #E8ECED;

/* ============= Button ============ */

$var__button--font-size                   : 14px;
$var__button--font-weight                 : 700;
$var__button--padding                     : 0 30px;
$var__button--transition                  : all ease-in-out 0.2s;
$var__button--height                      : 40px;
$var__button--border-style                : solid;
$var__button--border-width                : 1px;
$var__button--border-radius               : 4px;

$var__button-primary--color               : #111921;
$var__button-primary--background          : #C9CFD4;
$var__button-primary--border-color        : $var__button-primary--background;
$var__button-primary--color--hover        : #111921;
$var__button-primary--background--hover   : #E8ECED;
$var__button-primary--border-color--hover : $var__button-primary--background--hover;

$var__button-secondary--color             : #C9CFD4;
$var__button-secondary--background        : #141D27;
$var__button-secondary--border-color      : $var__button-secondary--background;
$var__button-secondary--color--hover      : #E8ECED;
$var__button-secondary--background--hover : #111921;
$var__button-secondary--border-color--hover: $var__button-secondary--background--hover;

$var__button-tertiary--color              : #E0E3E6;
$var__button-tertiary--background         : transparent;
$var__button-tertiary--border-color       : $var__grey--color-2;
$var__button-tertiary--color--hover       : #E8ECED;
$var__button-tertiary--background--hover  : #818899;
$var__button-tertiary--border-color--hover: $var__button-tertiary--background--hover;

$var__button-disable--color               : #818F99;
$var__button-disable--background          : #E8ECED;
$var__button-disable--border-color        : $var__button-disable--background;
$var__button-disable--color--hover        : $var__button-disable--color;
$var__button-disable--background--hover   : $var__button-disable--background;
$var__button-disable--border-color--hover : $var__button-disable--background--hover;

/* ============= Brand colors ============ */

$var__data-and-platform                   : #29A5CB;
$var__economics                           : #88B73A;
$var__reservoir-and-production            : #A5C855;
$var__exploration                         : #F87F14;
$var__drilling-and-well-construction      : #E5181B;
$var__halliburton-red                     : #CC0000;

/* ============= Utilities ============ */

$var__base--border-radius                 : 4px;
