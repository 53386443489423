@charset "UTF-8";
@import './variables-phase-2';

/* ============= Heading ============ */

$heading: 
  'h1' $var__h1--color $var__h1--font-size $var__h1--font-weight,
  'h2' $var__h2--color $var__h2--font-size $var__h2--font-weight,
  'h3' $var__h3--color $var__h3--font-size $var__h3--font-weight,
  'h4' $var__h4--color $var__h4--font-size $var__h4--font-weight;
@each $i in $heading {
  .#{nth($i, 1)},
  #{nth($i, 1)} {
    margin: 0;
    color: nth($i, 2);
    font-size: nth($i, 3);
    font-weight: nth($i, 4);
  }
}

/* ============= Button ============ */

@mixin button($color, $bg, $border-color, $color--hover, $bg--hover, $border-color--hover){
  font-family: $var__base--font-family;
  font-size: $var__button--font-size;
  font-weight: $var__button--font-weight;
  border-style: $var__button--border-style;
  border-width: $var__button--border-width;
  border-radius: $var__button--border-radius;
  border-color: $border-color;
  padding: $var__button--padding;
  transition: $var__button--transition;
  min-height: $var__button--height;
  color: $color;
  background-color: $bg;
  text-align: center;
  outline: none;

  &:hover,
  &:focus{
    color: $color--hover;
    background-color: $bg--hover;
    border-color: $border-color--hover;
    outline: none;
  }
}

.btn-primary{
  cursor: pointer;
  @include button(
    $var__button-primary--color,
    $var__button-primary--background,
    $var__button-primary--border-color,
    $var__button-primary--color--hover,
    $var__button-primary--background--hover,
    $var__button-primary--border-color--hover)
}

.btn-secondary{
  cursor: pointer;
  @include button(
    $var__button-secondary--color,
    $var__button-secondary--background,
    $var__button-secondary--border-color,
    $var__button-secondary--color--hover,
    $var__button-secondary--background--hover,
    $var__button-secondary--border-color--hover)
}

.btn-tertiary{
  cursor: pointer;
  @include button(
    $var__button-tertiary--color,
    $var__button-tertiary--background,
    $var__button-tertiary--border-color,
    $var__button-tertiary--color--hover,
    $var__button-tertiary--background--hover,
    $var__button-tertiary--border-color--hover)
}

.btn-disable{
  pointer-events: none;
  @include button(
    $var__button-disable--color,
    $var__button-disable--background,
    $var__button-disable--border-color,
    $var__button-disable--color--hover,
    $var__button-disable--background--hover,
    $var__button-disable--border-color--hover)
}

/* ============= Text Align ============ */

$textAlign: (
	left: left,
	right: right,
	center: center,
	inherit: inherit,
);
@each $name, $value in $textAlign {
	.text-#{$name} {
		text-align: $value;
	}
}

/* ============= Reset Spacing ============ */

$spacing: (
  0: 0,
  1: 10px,
  2: 20px
);
@each $name, $value in $spacing {
	.p-#{$name} {
		padding: $value;
  }
  
  .m-#{$name} {
		margin: $value;
  }

  .pt-#{$name} {
		padding-top: $value;
  }
  .pr-#{$name} {
		padding-right: $value;
  }
  .pb-#{$name} {
		padding-bottom: $value;
  }
  .pl-#{$name} {
		padding-left: $value;
  }

  .mt-#{$name} {
		margin-top: $value;
  }
  .mr-#{$name} {
		margin-right: $value;
  }
  .mb-#{$name} {
		margin-bottom: $value;
  }
  .ml-#{$name} {
		margin-left: $value;
  }

}