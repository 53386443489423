
@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500;600;700&display=swap');
@import '/src/assets/styles/_variables-phase-2';
@import '/src/assets/styles/_mixins-phase-2';

body {
  margin: 0;
  padding: 0;
	color: $var__base--color;
	font-family: $var__base--font-family;
	font-size: $var__base--font-size;
	font-weight: $var__base--font-weight;
	letter-spacing: $var__base--letter-spacing;
	background-color: $var__dark-grey--color-4;
	overflow-x: hidden;
}

ul,
li,
ol {
	padding: 0;
	margin: 0;
	list-style: none;
}
